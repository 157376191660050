<template>
  <validation-provider v-slot="{ errors }" :name="name" :rules="rules">
    <v-file-input
      v-model="localImage"
      :dense="dense"
      :error-messages="errors"
      :hide-input="onlyIcon"
      :label="label ? label : name"
      :loading="loading"
      :prepend-icon="onlyIcon ? 'mdi-paperclip' : ''"
      accept="imagePreview/png, imagePreview/jpeg, imagePreview/jpg"
      outlined
      prepend-inner-icon="mdi-paperclip"
      truncate-length="14"
      @change="$emit('change', $event)"
    />
  </validation-provider>
</template>

<script>
export default {
  name: 'KurccImageInput',
  props: {
    image: {
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    onlyIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      required: false,
      default: 'required'
    }
  },
  computed: {
    localImage: {
      get () {
        return this.image
      },
      set (v) {
        this.$emit('update:image', v)
      }
    }
  }
}
</script>
